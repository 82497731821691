<template>
  <a href="javascript:void(0)" v-on:click="reveal=!reveal" class="redacted" :style="{minWidth: length * scale + 'rem'}">
    {{reveal ? secret : output}}
  </a>
</template>

<script>
export default {
  name: 'redacted',
  data: function() {
    return {
      string: '¥#µ*$!Æ@&½€?',
      reveal: false,
      index: 0
    }
  },
  props: {
    secret: String,
    scaler: Number
  },
  computed: {
    output: function() {
      let temp = this.string + this.string
      return temp.substring(this.index, this.index + this.length)
    },
    length: function() {
      return this.secret.length
    },
    scale: function() {
      if (this.scaler) {
        return this.scaler
      } else {
        return 1
      }
    }
  },
  mounted: function() {
    setInterval(()=>{
      this.index++
      if (this.index>=this.string.length) {
        this.index=0
      }
    },83)
  }
}
</script>

<style lang="scss" scoped>
.redacted {
  display: inline-block;
  background-color: var(--primary);
  color: var(--bg);
  text-align: center;
  user-select: none;
}

a.redacted:hover, a.redacted:focus {
  animation: flash 1s infinite;
}
</style>