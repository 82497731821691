<template>
<div class="hack content" :class="[started ? 'started' : 'not-started', timeLeft<=0 || completed || (inputs ? inputs.length>=maxInputs : null) ? 'completed' : null]">

    <p class="title lores s21">
      <Redacted secret="hack" :scaler="2" />
    </p>

    <div class="game">

      <div class="upper">

        <div class="inputs" v-if="inputs">
          <div class="panel-title lores s12">buffer</div>
          <div class="input" v-for="index in maxInputs" :key="index">
            <div class="value lores s12" v-if="inputs[index-1]">{{inputs[index-1].value}}</div>
          </div>
        </div>

      </div>

      <div class="lower">

        <div class="grid" v-if="grid">
          <div class="panel-title lores s12">code matrix</div>
          <div class="row" v-for="(column, row) in grid" :key="row" :class="[orientation == 0 && row == activeRow && !limitReached ? 'choosable' : null]">
            <div class="cell lores s12" v-for="(hex, column) in column" :key="column"
              :class="['row-'+row, 'column-'+column, orientation == 1 && column == activeColumn && !limitReached ? 'choosable' : null, areCoordsChosen(column, row) ? 'chosen' : null]" v-on:click="click(column, row)">
              <div class="hex">{{hex}}</div>  
            </div>
          </div>

        </div>

        <div class="right-panel">

          <div class="goals" v-if="goals">
            <div class="panel-title lores s12">sequence</div>
            <div class="goal" v-for="(goal, index) in goals" :key="index">
              <div class="cell" v-for="(cell, cellIndex) in goal" :key="cellIndex" :class="[cell.match ? 'match' : null, matchedGoals[index].matches > cellIndex ? 'match' : null]">
                <div class="value lores s12">{{cell.value}}</div>
              </div>
            </div>
          </div>

          <div class="timer">
            <a href="javascript:void(0)" class="start lores s21" v-if="!started" v-on:click="start">start</a>
            <div class="countdown lores s21" v-else>{{timeLeft}}</div>
          </div>

          <div class="controls">
            <div class="control lores s21">
              <a href="javascript:void(0)" v-on:click="restart">restart</a>
            </div>
            <div class="control lores s21">
              <a href="javascript:void(0)" v-on:click="showSettings=!showSettings">options</a>
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="settings">
      <div class="setting lores s12" v-if="showSettings">
        buffer.length: <a href="javascript:void(0)" class="increment" v-on:click="maxInputs-=1;restart()">-</a><span class="value">{{maxInputs}}</span><a href="javascript:void(0)" class="increment" v-on:click="maxInputs+=1;restart()">+</a>
      </div>
      <div class="setting lores s12" v-if="showSettings">
        matrix.size: <a href="javascript:void(0)" class="increment" v-on:click="size-=1;restart()">-</a><span class="value">{{size}}</span><a href="javascript:void(0)" class="increment" v-on:click="size+=1;restart()">+</a>
      </div>
      <div class="setting lores s12" v-if="showSettings">
        sequence.count: <a href="javascript:void(0)" class="increment" v-on:click="goalCount-=1;restart()">-</a><span class="value">{{goalCount}}</span><a href="javascript:void(0)" class="increment" v-on:click="goalCount+=1;restart()">+</a>
      </div>
      <div class="setting lores s12" v-if="showSettings">
        time: <a href="javascript:void(0)" class="increment" v-on:click="timeLimit-=1;restart()">-</a><span class="value">{{timeLimit}}s</span><a href="javascript:void(0)" class="increment" v-on:click="timeLimit+=1;restart()">+</a>
      </div>
      <div class="setting lores s12" v-if="showSettings">
        matrix.options: <a href="javascript:void(0)" class="increment" v-on:click="optionsCount-=1;restart()">-</a><span class="value">{{optionsCount}}</span><a href="javascript:void(0)" class="increment" v-on:click="optionsCount+=1;restart()">+</a>
      </div>
    </div>

  </div>
</template>

<script>
import Redacted from '@/components/Redacted.vue'

export default {
  name: 'Hack',
  components: {
    Redacted
  },
  data: () => {
    return {
      maxInputs: 7,
      inputs: null,
      size: 6,
      optionsCount: 4,
      options: null,
      optionsOptions: [
        "1F",
        "D2",
        "3E",
        "A4",
        "55",
        "C6",
        "7A",
        "8B",
        "09"
      ],
      grid: null,
      goals: null,
      goalCount: 3,
      goalsTarget: null,
      orientation: 0,
      started: null,
      timeLimit: 30,
      timeSinceStart: null,
      showSettings: false
    }
  },
  mounted: function() {
    this.populate()
    setInterval(this.updateTime, 66)
  },
  methods: {
    restart: function() {
      this.inputs = null
      this.grid = null
      this.goals = null
      this.goalsTarget = null
      this.started = null
      this.timeSinceStart = null
      this.orientation = 0
      if (this.maxInputs<=1) {this.maxInputs=2}
      if (this.size<=1) {this.size=2}
      if (this.goalCount<=0) {this.goalCount=1}
      if (this.timeLimit<=0) {this.timeLimit=1}
      if (this.optionsCount<=1) {this.optionsCount=2}
      if (this.optionsCount>=this.optionsOptions.length) {this.optionsCount=this.optionsOptions.length}
      this.populate()
    },
    populate: function() {

      this.options = []

      this.optionsOptions = this.shuffle(this.optionsOptions)
      this.options = this.optionsOptions.slice(0, this.optionsCount)

      this.goalsTarget = []

      for (let i = 0; i < this.goalCount; i++) {
        this.goalsTarget.push(i+2)
      }

      this.inputs = []

      this.grid = []
      for (let y = 0; y < this.size; y++) {
        let row = []
        for (let x = 0; x < this.size; x++) {
          row.push (
            this.options[Math.floor(Math.random()*this.options.length)]
          )
        }
        this.grid.push(row)
      }

      this.goals = []

      for (let i = 0; i < this.goalsTarget.length; i++) {
        this.goals.push(this.createGoal(this.goalsTarget[i]))
      }

    },
    updateTime: function() {
      if (this.started && this.inputs.length < this.maxInputs && !this.completed) {
        this.timeSinceStart = Date.now() - this.started
      }
    },
    rowCells: function(row) {
      let cells = []
      for (let i = 0; i < this.size; i++) {
        cells.push(this.grid[row][i])
      }
      return cells
    },
    columnCells: function(column) {
      let cells = []
      for (let i = 0; i < this.size; i++) {
        cells.push(this.grid[i][column])
      }
      return cells
    },
    randInt: function(min, max) { // https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    shuffle: function(array) { //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    createGoal: function(length) {
      let goal = []
      let orientation = this.randInt(0,1)
      for (let i = 0; i < length; i++) {
        if (goal.length==0) {
          let cell = {
            x: this.randInt(0, this.size - 1),
            y: this.randInt(0, this.size - 1),
            value: null
          }
          cell.value = this.grid[cell.x][cell.y]
          goal.push(cell)
        } else {
          if (orientation==0) {
            let cell = {
              x: goal[goal.length - 1].x,
              y: this.randInt(0, this.size - 1),
              value: null
            }
            cell.value = this.grid[cell.x][cell.y]
            goal.push(cell)
            orientation=1
          } else {
            let cell = {
              x: this.randInt(0, this.size - 1),
              y: goal[goal.length - 1].y,
              value: null
            }
            cell.value = this.grid[cell.x][cell.y]
            goal.push(cell)
            orientation=0
          }
        }
      }
      return goal
    },
    click: function(x, y) {

      if (this.completed) {
        return
      }

      if (this.timeLeft<=0) {
        return
      }

      if (!this.started) {
        return
      }

      if (this.limitReached) {
        return
      }
  
      let cell = {
        x: x,
        y: y,
        value: this.grid[y][x]
      }

      if (this.isCellChosen(cell)) {
        return
      }

      if (this.orientation == 0) {
        if (y == this.activeRow) {
        } else {
          return
        }
      } else {
        if (x == this.activeColumn) {
        } else {
          return
        }
      }

      this.inputs.push(cell)
      this.orientation++;
      if (this.orientation > 1) {
        this.orientation = 0
      }
    },
    start: function() {
      this.started = new Date()
    },
    isCellChosen: function(cell) {
      if (this.inputs.length==0) {return false}
      for (let i = 0; i < this.inputs.length; i++) {
        const e = this.inputs[i];
        if (e.x == cell.x && e.y == cell.y) {
          return true
        }
      }
      return false
    },
    areCoordsChosen: function(x, y) {
      return this.isCellChosen({
        x: x,
        y: y
      })
    }
  },
  computed: {
    timeLeft: function() {
      let timeLeft = parseFloat(this.timeLimit - (this.timeSinceStart / 1000)).toFixed(2)
      if (timeLeft <= 0) {timeLeft = 0}
      return timeLeft
    },
    activeRow: function() {
      if(this.inputs.length<=0) {
        return 0
      } else {
        return this.inputs[this.inputs.length-1].y
      }
    },
    activeColumn: function() {
      if(this.inputs.length<=0) {
        return 0
      } else {
        return this.inputs[this.inputs.length-1].x
      }
    },
    limitReached: function() {
      return this.inputs.length == this.maxInputs
    },
    matchedGoals: function() {
      if(!this.inputs||!this.goals){return}
      let matchedGoals = []
      let inputString = ""
      for (let i = 0; i < this.inputs.length; i++) {
        inputString+=this.inputs[i].value;
      }

      for (let i = 0; i < this.goals.length; i++) {
        let goal = this.goals[i];
        let goalString = ""
        for (let j = 0; j < goal.length; j++) {
          goalString+=goal[j].value;
        }
        if (inputString.includes(goalString)) {
          matchedGoals.push({
            length: goal.length,
            matches: goal.length
          })
        } else {
          matchedGoals.push({
            length: goal.length,
            matches: 0
          })
        }
      }

      return matchedGoals
    },
    completed: function() {
      if (!this.matchedGoals) {return false}
      for (let i = 0; i < this.matchedGoals.length; i++) {
        if (this.matchedGoals[i].matches < this.matchedGoals[i].length) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.hack .game {
  display: inline-block;
}

.hack.started .panel-title {
  display: none;
}

.hack .panel-title {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--tertiary);
  color: var(--bg);
  padding: 0 1rem 0 .5rem;
  z-index: 1;
}

.hack .panel-title:before {
  background-color: var(--tertiary);
  width: 100%;
  height: calc(100% + 1px);
  content: "";
  position: absolute;
  right: 2px;
  top: 0;
  transform: skewX(45deg) translateX(-1rem);
  z-index: -1;
  box-shadow: -1rem 1rem 0 var(--bg);
}

.hack .inputs, .hack .grid, .hack .goals, .hack .timer {
  position: relative;
  border: 1px solid var(--tertiary);
  padding: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  vertical-align: top;
  background-color: var(--bg);
  overflow: hidden;
}

.hack .inputs, .hack .grid .row, .hack .goals .goal {
  white-space: nowrap;
}

.hack .inputs, .hack .grid, .hack .goals, .hack .timer, .hack .settings {
  user-select: none;
}

.hack .settings {
  margin-bottom: 2rem;
}

.hack .settings .setting, .hack .controls.inline .control {
  display: inline-block;
}

.hack .settings .setting:not(:last-child), .hack .settings .setting, .hack .controls.inline .control:not(:last-child) {
  margin-right: 2rem;
}

.hack .settings .setting .increment {
  padding: 0 .5rem;
}

.hack .settings .setting .value {
  padding: 0 .5rem;
}

.hack .timer {
  padding: 0;
  text-align: center;
}

.hack .timer .start {
  display: block;
  background-color: var(--tertiary);
  color: var(--bg);
}

.hack.completed .timer .countdown {
  background-color: var(--tertiary);
  color: var(--bg);
}

.hack .timer .start:hover {
  background-color: var(--primary);
}

.hack .inputs, .hack .goals .goal {
  height: 2rem;
}

.hack .goals .goal:not(:last-child) {
  margin-bottom: 1rem;
}

.hack .inputs .input, .hack .goals .goal .cell {
  width: 2rem;
  height: 100%;
  border: 1px dashed var(--tertiary);
  display: inline-block;
  position: relative;
}

.hack .inputs .input:not(:last-child), .hack .goals .goal .cell:not(:last-child) {
  margin-right: 1rem;
}

.hack .goals .goal .cell.match {
  background-color: var(--secondary);
  color: var(--bg);
  border-color: var(--secondary);
}

.hack .inputs .input .value, .hack .goals .cell .value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hack .grid, .hack .right-panel {
  display: inline-block;
}

.hack .grid .row {
  height: 4rem;
}

.hack .grid .row .cell {
  display: inline-block;
  width: 4rem;
  height: 100%;
  position: relative;
}

.hack .grid .row .cell .hex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hack .grid .row .cell {
  transition-duration: 0s;
}

.hack.started:not(.completed) .grid .row .cell:hover {
  background-color: var(--primary);
  opacity: .5;
  color: var(--bg);
}

.hack.started:not(.completed) .grid .row .cell.choosable:hover, .hack.started:not(.completed) .grid .row.choosable .cell:hover {
  background-color: var(--secondary);
  opacity: 1;
  color: var(--bg);
}

.hack.started:not(.completed) .grid .row.choosable .cell, .hack.started:not(.completed) .grid .row .cell.choosable {
  background-color: var(--primary);
  color: var(--bg);
}

.hack .grid .row .cell.chosen {
  background-color: var(--bg);
  color: var(--primary);
  opacity: .5;
}

.hack.started:not(.completed) .grid .row.choosable .cell.chosen, .hack.started:not(.completed) .grid .row .cell.choosable.chosen {
  background-color: var(--primary);
  color: var(--bg);
  opacity: .5;
}

.hack.not-started .grid .row .cell, .hack.not-started .grid .row .cell:hover, .hack.not-started .goals .goal .cell {
  color: transparent;
  background-color: transparent;
}

.hack.not-started .grid .row .cell {
  border-top: 1px dashed var(--tertiary);
  border-left: 1px dashed var(--tertiary);
  box-sizing: border-box;
}

.hack.not-started .grid .row .cell:last-child {
  border-right: 1px dashed var(--tertiary);
}

.hack.not-started .grid .row:last-child .cell {
  border-bottom: 1px dashed var(--tertiary);
}
</style>
